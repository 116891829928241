import { Typography } from "@mui/material"

interface TitleProps {

}

const title = "Welkom bij Glazenwasserij Wens"

const styles = {
    text: {
        textAlign: "center",
        color: "white",
        borderRadius: "20px",
        py: {xs: 2, lg: 4},
        px: {xs: 4, lg: 12},
        fontSize: {xs: 18, lg: 36},
        fontFamily: "Arial",
        fontWeight: "bold"
    }
}

export default function Title(props: TitleProps) {
    return <Typography variant="h1" sx={styles.text}>{title.toUpperCase()}</Typography>
}