import { Grid } from "@mui/material";
import BlueSideBar from "../../components/BlueSideBar";
import Gallery from "../../components/Gallery";
import i1 from "../../img/softwash/1.jpeg";
import i2 from "../../img/softwash/2.jpeg";
import i3 from "../../img/softwash/3.jpeg";
import i4 from "../../img/softwash/4.jpeg";
import i5 from "../../img/softwash/5.jpeg";
import i6 from "../../img/softwash/6.jpeg";
import { softwashPage } from "../../data/pages";
import useIsMobile from "../../utils/isMobile";

const images = [
    {
        img: i1,
        title: "Softwash crepi gevel"
    },
    {
        img: i2,
        title: "Softwash crepi gevel"
    },
    {
        img: i3,
        title: "Softwash crepi gevel"
    },
    {
        img: i4,
        title: "Softwash crepi gevel"
    },
    {
        img: i5,
        title: "Softwash crepi gevel"
    },
    {
        img: i6,
        title: "Softwash crepi gevel"
    }
]

const headerText = `
Softwash is een reinigingstechniek waarbij er met 100% biologisch afbreekbare producten wordt gewerkt. 

Door deze producten wordt het vuil bij de wortel aangepakt.
Om vervolgens het vuil te verwijderen van uw gevel, dak, of oprit wordt gebruik gemaakt van een zachte waterstraal.
`

function Photos() {

    return <Grid item p={{xs: 0, lg: 8}}>
        <Gallery images={images} />
    </Grid>
}

function Body() {
    return <Grid item xs={12} md={8}>
        <Grid container direction="column">
            <Photos />
        </Grid>
    </Grid>
}
function Softwash() {
    return useIsMobile() ? (
        <Grid container direction="column" pt={10}>
            <BlueSideBar width={12} page={softwashPage} headerText={headerText}/>
            <Body />
        </Grid>
    ) : (
        <Grid container direction="row" pt={12}>
            <BlueSideBar width={4} page={softwashPage} headerText={headerText}/>
            <Body />
        </Grid>
    )
}

export default Softwash;