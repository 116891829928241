import { Grid, Stack } from '@mui/material';
import { Typography, Divider } from '@mui/material';
import ContactChips from './ContactChips';
import Title from './Title';

const glazenwasserijText = `
Glazenwasserij Wens is uw eerste aanspreekpunt als het op schoonmaak aankomt. Niet alleen voor de ramen van uw woning of kantoorgebouw kan u ons contacteren, maar ook voor uw koepels en veranda's kan u bij ons terecht. 
Is je gevel, oprit of dakgoot aan reiniging toe? Dan komen wij graag eens langs voor een vrijblijvende offerte. 
Ga je liever zelf aan de slag? 
Dan kan je ook bij ons terecht voor verhuur van onze machines. Een specifieke vraag? Aarzel niet om ze te stellen.
`

const mobileBannerTitle = "Welkom bij Glazenwasserij Wens!"
const mobileBannerText = "Op deze website vindt u meer informatie over Glazenwasserij Wens en de machines die door ons verhuurd worden."

interface WebBannerProps {
    backgroundColor: string
    textColor: string
    imgFirst: Boolean
    text: string
}

const styles = {
    main: {
        minHeight: "700px",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        alignItems: "center"
    }
}

export function WebBanner(props: WebBannerProps) {
    return <Grid 
        container
        id="home"
        sx={[{ 
            background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url("/cover.jpeg");`}, 
            styles.main]}
    >

        <Grid item p={8} height="50%" xs={12}>
            <Stack direction="column" alignItems={"center"}>
                <Title />
                <ContactChips />
            </Stack>
        </Grid>

    </Grid>
}

function Banner() {
    const banner1: WebBannerProps = {
        backgroundColor: "#1A1917",
        textColor: "white",
        imgFirst: true,
        text: glazenwasserijText,
    }


    return <WebBanner {...banner1} />
}

export default Banner;