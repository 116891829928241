import { Grid } from "@mui/material";
import BlueSideBar from "../../components/BlueSideBar";
import Gallery from "../../components/Gallery";
import i1 from "../../img/glazenwasserij/1.jpeg";
import i3 from "../../img/glazenwasserij/3.jpeg";
import i4 from "../../img/glazenwasserij/4.jpeg";
import i5 from "../../img/glazenwasserij/5.jpeg";
import i6 from "../../img/glazenwasserij/6.jpeg";
import i7 from "../../img/glazenwasserij/7.jpeg";
import i10 from "../../img/glazenwasserij/10.jpeg";
import i11 from "../../img/glazenwasserij/11.jpeg";
import i12 from "../../img/glazenwasserij/12.jpeg";
import i21 from "../../img/glazenwasserij/21.jpeg";
import i23 from "../../img/glazenwasserij/23.jpeg";
import i25 from "../../img/glazenwasserij/25.jpeg";
import i26 from "../../img/glazenwasserij/26.jpeg";
import i27a from "../../img/glazenwasserij/27a.jpeg";
import i27b from "../../img/glazenwasserij/27b.jpeg";
import { wensPage } from "../../data/pages";
import useIsMobile from "../../utils/isMobile";

const images = [
    {
        img: i1,
        title: "i1"
    },
    {
        img: i3,
        title: "i3"
    },
    {
        img: i4,
        title: "i4"
    },
    {
        img: i5,
        title: "i5"
    },
    {
        img: i6,
        title: "i6"
    },
    {
        img: i7,
        title: "i7"
    },
    {
        img: i10,
        title: "i10"
    },
    {
        img: i11,
        title: "i11"
    },
    {
        img: i12,
        title: "i12"
    },
    {
        img: i21,
        title: "i"
    },
    {
        img: i23,
        title: "i"
    },
    {
        img: i25,
        title: "i"
    },
    {
        img: i26,
        title: "i"
    },
    {
        img: i27a,
        title: "i"
    },
    {
        img: i27b,
        title: "i"
    }
]

const headerText = `Bij Glazenwasserij Wens kunt u terecht voor al uw schoonmaakwerken. 
    Van ramen en omlijstingen, koepels en veranda's tot gevels, opritten en dakgoten. 

    Heeft u nog andere vragen? Neem gerust contact op!

    Bekijk zeker ook onze foto's!
`

function Photos() {

    return <Grid item p={{xs: 0, lg: 8}}>
        <Gallery images={images} />
    </Grid>
}

function Body() {
    return <Grid item xs={12} md={8}>
        <Grid container direction="column">
            <Photos />
        </Grid>
    </Grid>
}
function Glazenwasserij() {
    return useIsMobile() ? (
        <Grid container direction="column" pt={10}>
            <BlueSideBar width={12} page={wensPage} headerText={headerText}/>
            <Body />
        </Grid>
    ) : (
        <Grid container direction="row" pt={12}>
            <Body />
            <BlueSideBar width={4} page={wensPage} headerText={headerText}/>
        </Grid>
    )
}

export default Glazenwasserij;