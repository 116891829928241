import React from "react";
import { Grid, Divider as MUIDivider, Typography, Link } from "@mui/material";
import Page from "../../types/page";
import useIsMobile from "../../utils/isMobile";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { themeColors } from "../../theme/theme";

interface SideBarProps {
    width: number
    page: Page
    headerText: string | React.ReactElement
}

interface ContactInfoProps {
    page: Page
    headerText: string | React.ReactElement
}

const ContactInfo = (props: ContactInfoProps) => {

    return <Grid container 
        direction="column" 
        alignItems="center" 
        justifyContent="center"
        pt={2} pb={2}
    >
        <PageTitle name={props.page.name} />  
        <Divider />
        <HeaderText headerText={props.headerText} />
        <Divider />
        <Contact page={props.page}/>
        <Divider />
    </Grid>
}

const BlueSideBar = (props: SideBarProps) => {
    return <Grid item 
        style={{backgroundColor: themeColors.primary, minHeight: "100px"}}
        xs={12}
        md={props.width}
        py={{lg: 12, md: 0}} 
    >
        <ContactInfo page={props.page} headerText={props.headerText}/>
    </Grid>
}

function PageTitle(props: any) {
    const variant = useIsMobile() ? "h5" : "h3"
    return <Grid item>
        <Typography variant={variant} style={{ color: "white" }} align="center">
            {props.name.toUpperCase()}
        </Typography>
    </Grid>      
}
function HeaderText(props: any) {
    return <Grid item pl={2} pr={2}>
        <Typography variant="body1" style={{ color: "white", fontSize: 18, whiteSpace: "pre-wrap"}} align="center">
            {props.headerText}
        </Typography>
    </Grid>
}

function Phone(props: any) {
    return <Grid item>
        <Typography variant="body1">
            <Link href={"tel: " + props.phone} underline="none" color="white">
                {props.phone}
            </Link>
        </Typography>
    </Grid>;
}

function Email(props: any) {
    return <Grid item>
        <Typography variant="body1" style={{ color: "white" }}>
            <Link href={"mailto: " + props.email} underline="none" color="white">
                {props.email}
            </Link>
        </Typography>
    </Grid>
}

function Contact(props: any) {
    return <Grid item>
        <Grid container direction="column" justifyContent="center" alignItems="center" rowSpacing={2}>
            <Grid item pt={1}><Typography variant="h6" style={{ color: "white" }}>Contact</Typography></Grid>
            <Phone phone={props.page.phone} />
            <Email email={props.page.email} />
            <Grid item>
                <Grid container direction="row">
                    <Link href={props.page.facebook}><Facebook sx={{ fontSize: 40, color: "white" }}/></Link>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}
const Divider = () => {
    const margin = useIsMobile() ? 1 : 3
    return <>
        <Grid item mt={margin} mb={margin} />
            <MUIDivider variant="middle" orientation="horizontal" light flexItem style={{ backgroundColor: "white" }}/>
        <Grid item mt={margin} mb={margin} />
    </>
}

export default BlueSideBar;
