import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Home, Glazenwasserij, MachineVerhuur } from "./pages";
import { AppBar } from './components/AppBar';
import { Footer } from './components/Footer';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import Softwash from './pages/Softwash/Softwash';
import Stoomreiniging from './pages/Stoomreiniging/Stoomreiniging';

const routes = [
 {
   name : "home",
   path : "/",
   element : <Home />
 },
 {
   name : "glazenwasserij",
   path : "/glazenwasserij",
   element : <Glazenwasserij />
 },
 {
   name : "softwash",
   path : "/softwash",
   element : <Softwash />
 },
 {
   name : "stoomreiniging",
   path : "/stoomreiniging",
   element : <Stoomreiniging />
 },
 {
   name: "machineverhuur",
   path : "/machineverhuur",
   element : <MachineVerhuur />
 }
]

const ScrollToTop = (props: any) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

function App() {
  return (
    <ThemeProvider theme={theme}>
    <AppBar />
      <BrowserRouter>
      <ScrollToTop >
        <Routes>
          { routes.map((route) => <Route key={route.name} path={route.path} element={route.element}/>)}
        </Routes>
        
      </ScrollToTop>
      </BrowserRouter>
    <Footer />
    </ThemeProvider>
  );

}

export default App;
