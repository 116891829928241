import { Button } from "@mui/material"

interface MenuLinkProps {
    name: string
    link: string
}

export default function MenuLink(props: MenuLinkProps) {
    

    return <Button sx={{px: 3}} variant="text" onClick={() => {window.location.pathname = props.link}}>
        {props.name}
    </Button>
}