import { Card, CardMedia, CardContent, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider } from "@mui/material";
import Carousel from 'react-material-ui-carousel'
import FullDay from '@mui/icons-material/Brightness5';
import Info from '@mui/icons-material/Info';

import BlueSideBar from "../../components/BlueSideBar";
import i3 from "../../img/machineverhuur/3.jpeg";
import i8 from "../../img/machineverhuur/8.png";
import i9 from "../../img/machineverhuur/9.jpeg";
import i10 from "../../img/machineverhuur/10.jpeg";

import { machinePage } from "../../data/pages";
import useIsMobile from "../../utils/isMobile";

const machines = [
    {
        name: "Tapijt- en zetelreiniger",
        img: [i9],
        price_day: "€60",
        price_half_day: "€30",
        info: "Inclusief product"
    },
    {
        name: "Dibo JMB hogedrukreiniger op diesel",
        img: [i3, i10],
        price_day: "€250 excl. diesel",
        price_half_day: "€150",
        info: "Tot 250bar en 110°C. Extra stoomfunctie tot 140°C"
    },
    {
        name: "Stoompan",
        img: [i8],
        price_day: "€30",
        price_half_day: "€30",
        info: "Kan optioneel bij hogedrukreiniger"
    }
]

const Machines = () => {
    const padding = useIsMobile() ? 2 : 8
    const orientation = useIsMobile() ? "column" : "row"
    return <Grid container 
                p={padding}
                justifyContent="space-between"
                alignItems={"stretch"}
                direction={orientation}
                spacing={8}
                >
        {machines.map((machine) => <Grid item xs={12} md={6} mb={2} key={machine.name} justifyContent="center" alignItems="center">
            <MachineCard machine={machine}/>
        </Grid>)}
    </Grid>
}

interface Machine {
    name: string
    img: Array<any>
    price_day: string
    price_half_day: string
    info: string
}

interface MachineCardProps {
    machine: Machine
}
const MachineCard = (props: MachineCardProps) => {
    const isOnlyOneImage = props.machine.img.length === 1;
    return <Card>
        <Carousel 
            autoPlay={false} 
            navButtonsAlwaysVisible={!isOnlyOneImage}
            navButtonsAlwaysInvisible={isOnlyOneImage}
            indicators={false} >
            {props.machine.img.map((img, index) => <CardPhoto img={img} key={index.toString()}/>)}
        </Carousel>
        <CardContent>
            <Typography variant="h5" style={{ fontSize: 18}} gutterBottom>{props.machine.name}</Typography>
            <List>
                <Divider variant="middle"/>
                <ListItem>
                    <ListItemIcon>
                        <FullDay />
                    </ListItemIcon>
                    <ListItemText primary={"Dagprijs: " + props.machine.price_day} />
                </ListItem>

                <ListItem >
                    <ListItemIcon>
                        <Info />
                    </ListItemIcon>
                    <ListItemText primary={props.machine.info} />
                </ListItem>
            </List>

        </CardContent>
    </Card>
}

const CardPhoto = (props: {img: any}) => {
    return <CardMedia 
        component="img"
        image={props.img}
        height={"500px"}
        sx={{objectFit: "contain"}}
    />
}

const headerText = "U kunt bij ons terecht voor verhuur van de getoonde toestellen. Nog vragen? Neem gerust contact op!"

function Body() {
    return <Grid item xs={12} md={8} pt={4}>
        <Machines />
    </Grid>
}
function MachineVerhuur() {
    return useIsMobile() ? (
        <Grid container direction="column" style={{width: "100vw"}} pt={10}>
            <BlueSideBar width={12} page={machinePage} headerText={headerText}/>
            <Body />
        </Grid>
    ) : (
        <Grid container direction="row" pt={12}>
            <Body />
            <BlueSideBar width={4} page={machinePage} headerText={headerText}/>
        </Grid>
    )
}

export default MachineVerhuur;