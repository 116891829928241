import React from "react";
import { Grid, Link, Stack } from "@mui/material";
import { wensPage } from "../../data/pages";
import Page from "../../types/page";
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { Facebook, LinkedIn } from "@mui/icons-material";
import { themeColors } from "../../theme/theme";

interface IconProps {
    icon: React.ReactElement
    text: string | undefined
}

function Icon(props: IconProps) {
    const iconProps: any = {
        style: {
            fill: "white",
            marginRight: "10px"
        }
    }
    let Icon = props.icon.type
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    }}>
        
        {<Icon {...iconProps}/>}
        <span>{props.text}</span>
    </div>  
}

function companyFooter(page: Page): React.ReactElement {
    const lineStyle = {color: "white"}

    return <Grid item pt={4}>
        <Grid container direction="column">
            <Grid item style={{...lineStyle, fontWeight: 600}}>Glazenwasserij Wens</Grid>
            <Grid item style={lineStyle}><Icon icon={<CallIcon />} text={page.phone}/></Grid>
            <Grid item style={lineStyle}><Link href={"mailto:" + page.email} sx={{color: "white"}}><Icon icon={<MailIcon />} text={page.email}/></Link></Grid>
            <Grid item style={lineStyle} >
                <Grid container direction="row">
                    <Link href={page.facebook}><Icon text="" icon={<Facebook />}/></Link>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

function WensFooter() {
    return companyFooter(wensPage)
}

function Footer(): React.ReactElement {

    return (
    <Grid 
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="space-evenly"
        pb={4}
        sx={{backgroundColor: themeColors.primary, width: "100%"}}
    >
        <WensFooter />

        <Grid item pt={4}>
            <Stack direction={"column"} alignItems={"flex-start"} spacing={0}>
                <Link href="/sitemap.xml" sx={{color: "white"}}>{"Sitemap"}</Link>
                <Link href="https://agits.be" sx={{color: "white"}}>© {new Date().getFullYear()} {"AG IT Solutions"}</Link>
            </Stack>
        </Grid>
    </Grid>
    )
}

export default Footer;