import { Typography } from "@mui/material"
import ImgRamen from "../img/glazenwasserij/1.jpeg"
import ImgTapijtReiniger from "../img/machineverhuur/3.jpeg"

import Page from "../types/page"

const Text = (props: any) => (
  <Typography variant="body2" color="text.secondary">
    {props.children}
  </Typography>
)
const pages: Array<Page> = [
  {
    name: "Home",
    path: "/",
    image: null,
    small_text: ""
  },
  {
    name: "Glasbewassing",
    path: "/glazenwasserij",
    image: ImgRamen,
    small_text: (<>
      <Text>Schoonmaakwerken van</Text>
      <ul>
        <li><Text>Ramen en omlijstingen</Text></li>
        <li><Text>Koepels en veranda's</Text></li>
        <li><Text>Gevels en opritten</Text></li>
        <li><Text>Dakgoten</Text></li>
      </ul>
      <Text>Klik hier om een kijkje te nemen naar onze foto's!</Text>
      <Text>Nog andere vragen? Contacteer ons gerust!</Text>
    </>),
    phone: "+32 474 06 09 06",
    email: "info@axelwens.be",
    facebook: "https://www.facebook.com/Glazenwasserij-Wens-102937868451247",
    linkedin: "https://www.linkedin.com/in/axel-wens-6a5016231/"
  },
  {
    name: "Softwash",
    path: "/softwash",
    image: ImgTapijtReiniger,
    small_text: (<>
      <Text>Verhuur van machines</Text>
      <ul>
        <li><Text>Tapijtenreiniger</Text></li>
        <li><Text>Stoomreiniger</Text></li>
        <li><Text>Bulldozer</Text></li>
        <li><Text>Remorque</Text></li>
        <li><Text>Rijplaten</Text></li>
      </ul>
      <Text>Klik hier voor prijzen en foto's!</Text>
    </>),
    phone: "+32 474 06 09 06",
    email: "info@axelwens.be",
    facebook: "https://www.facebook.com/Glazenwasserij-Wens-102937868451247",
    linkedin: "https://www.linkedin.com/in/axel-wens-6a5016231/"
  },
  {
    name: "Stoomreiniging",
    path: "/stoomreiniging",
    image: ImgTapijtReiniger,
    small_text: (<>
      <Text>Verhuur van machines</Text>
      <ul>
        <li><Text>Tapijtenreiniger</Text></li>
        <li><Text>Stoomreiniger</Text></li>
        <li><Text>Bulldozer</Text></li>
        <li><Text>Remorque</Text></li>
        <li><Text>Rijplaten</Text></li>
      </ul>
      <Text>Klik hier voor prijzen en foto's!</Text>
    </>),
    phone: "+32 474 06 09 06",
    email: "info@axelwens.be",
    facebook: "https://www.facebook.com/Glazenwasserij-Wens-102937868451247",
    linkedin: "https://www.linkedin.com/in/axel-wens-6a5016231/"
  },
  {
    name: "Verhuur",
    path: "/machineverhuur",
    image: ImgTapijtReiniger,
    small_text: (<>
      <Text>Verhuur van machines</Text>
      <ul>
        <li><Text>Tapijtenreiniger</Text></li>
        <li><Text>Stoomreiniger</Text></li>
        <li><Text>Bulldozer</Text></li>
        <li><Text>Remorque</Text></li>
        <li><Text>Rijplaten</Text></li>
      </ul>
      <Text>Klik hier voor prijzen en foto's!</Text>
    </>),
    phone: "+32 474 06 09 06",
    email: "info@axelwens.be",
    facebook: "https://www.facebook.com/Glazenwasserij-Wens-102937868451247",
    linkedin: "https://www.linkedin.com/in/axel-wens-6a5016231/"
  }
]

const wensPage = pages[1];
const softwashPage = pages[2];
const steamPage = pages[3];
const machinePage = pages[4];

export { wensPage, softwashPage, steamPage, machinePage }
export default pages;