import Banner from "./Banner/Banner";
import { Box } from "@mui/material";
import Services from "./Services/Services";

const Home = () => {
    return <Box sx={{width: "100vw"}}>
        <Banner />
        <Services />
    </Box>
};

export default Home;