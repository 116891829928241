import { createTheme } from "@mui/material";

let themeColors = {
    primary: "#00A0DC",
    secondary: "#1B1918"
}
let theme = createTheme({
    palette: {
        primary: {
            main: themeColors.primary
        },
        secondary: {
            main: themeColors.secondary
        }
    },
    typography: {
        fontFamily: "Arial",
        body1: {
            color: themeColors.primary,
            fontSize: 16,
            lineHeight: 1.2
        },
        body2: {
            color: themeColors.primary,
            fontSize: 24
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                    letterSpacing: 3
                }
            }
        },
        MuiStack: {
            defaultProps: {
                spacing: 2,
                alignItems: "center"
            }
        },
        MuiChip: {
            styleOverrides: {

              sizeMedium: {
                fontSize: "20px !important",
                padding: "24px !important"
              },
              colorPrimary: {
                color: 'white',
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "white",
                    color: themeColors.primary
                }
              },
              colorSecondary: {
                color: 'white',
              },
            },
          }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 0,
            md: 700,
            lg: 1300,
            xl: 1300
        }
    }
})

export {theme, themeColors}