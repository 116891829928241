import { useState, useEffect } from "react";
import { theme } from "../theme/theme";

function useIsMobile() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return width <= theme.breakpoints.values.lg;
}

export default useIsMobile;