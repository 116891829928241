import { Box, Container, Grid, Link, Typography } from "@mui/material";

import ImgRamen from "../../../img/glazenwasserij/1.jpeg";
import ImgVerhuur from "../../../img/machineverhuur/3.jpeg";
import { useState } from "react";
import Subtitle from "./Subtitle";
import Title from "./Title";
interface ServicesProps {};

interface Service {
    name: string
    href: string
    img: any
} 
const services = [
    {
        name: "Glasbewassing",
        href: "glazenwasserij",
        img: ImgRamen
    },
    {
        name: "Softwash",
        href: "softwash",
        img: `/softwash.jpeg`
    },
    {
        name: "Stoomreiniging",
        href: "stoomreiniging",
        img: `/stoomreiniging.jpeg`

    },
    {
        name: "Verhuur",
        href: "machineverhuur",
        img: ImgVerhuur
    }
]

const styles = {
    box: {
        width: "300px",
        height: "300px",
        margin: 4,
        borderRadius: "25px",
        zIndex: 1,
        "&:hover": {
            backgroundColor: "white"
        },
        backgroundColor: "black",
        position: "relative",
        textAlign: "center",
        overflow: "hidden"
    },
    image: {
        width: "100%",
        height: "100%",
        opacity: 0.5,
    },
    text: {
        zIndex: 3,
        fontSize: 24,
        fontWeight: "bold",
        color: "black",
        margin: "auto"
    },
    textBox: {
        opacity: 1,
        position: "absolute",
        zIndex: 10,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center"
    },
    link: {
        height: "300px",
        width: "300px",
        position: "absolute",
        zIndex: 20,
    }
}


export default function Services(props: ServicesProps) {
    return <Container style={{maxWidth: "1400px"}}><Grid container>
        <Grid item xs={12}>
            <Title />
        </Grid>
        <Grid item xs={12}>
            <Subtitle />
        </Grid>
        {
            services.map((service) => <ServiceCard key={service.name} {...service} />)
        }
        
    </Grid></Container>
}

function ServiceCard(service: Service) {
    const [hover, setHover] = useState(false);

    const onMouseHover = () => setHover(true);
    const onMouseOut = () => setHover(false);

    return <Grid item xs={12} md={6} lg={3} key={service.name} textAlign={"center"} sx={{display: "flex", alignItems: "center", flexDirection: "column"}}>
        
            <Box sx={styles.box} onMouseOver={onMouseHover} onMouseOut={onMouseOut}>
                <Link href={service.href} sx={styles.link}><Box sx={styles.textBox}><Typography sx={[styles.text, {color: hover ? "black": "white"}]}>{service.name.toUpperCase()}</Typography></Box> </Link>
                <img src={service.img} alt="Glazenwasserij Wens" style={styles.image} />
            </Box>
       
    </Grid>;
}
