import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import pages from '../../data/pages';
import logo from "../../img/logo-zwart.jpg";

import Page from '../../types/page';
import { Stack } from '@mui/material';
import useIsMobile from '../../utils/isMobile';
import { themeColors } from '../../theme/theme';
import { Close } from '@mui/icons-material';
import MenuLink from './MenuLink';

const styles = {
  logo: {
      width: {lg: "400px", md: "200px"}
  },
  headerButton: {
    my: 2,
    color: 'white', 
    display: 'block',
    fontSize: {md: 20, sm: 16},
    "&:hover": {
      color: "black",
      backgroundColor: "white"
    }
  },
  menu: {
    position: "absolute", 
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    pr: 2,
    m: "0px !important",
    zIndex: 100,
    backgroundColor: "lightgrey",
    color: "white",
    alignItems: "flex-start",
    "& .MuiStack-root": {
        marginLeft: 0
    }
  },
  menuHeader: {
      backgroundColor: "white",
      justifyContent: "space-between",
      width: "100%"
  }
}

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElNav);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElNav(null);
  };
  const isMobile = useIsMobile();

  return (
    <AppBar position="fixed" sx={{ minWidth: "100vw", backgroundColor: "#1B1918" }}>
            
        <Toolbar disableGutters>
          <Stack sx={{ display: { xs: undefined, lg: 'none' }}} width={"100%"} pr={4} direction="row" justifyContent={"space-between"}>
            <Link href="/" height={"100%"}><img src={logo} alt="Glazenwasserij Wens Malle" style={{width: isMobile ? "200px" : "400px"}}/></Link>

            <IconButton
              onClick={handleOpenNavMenu}
              sx={{color: "white"}}
            >
              <MenuIcon />
            </IconButton>
            <Stack direction={"column"} sx={[{display: open ? "flex" : "none"}, styles.menu]}>
              <Stack direction="row" sx={styles.menuHeader} >
                  <img src={logo} alt="Glazenwasserij Wens" height="30px" style={{marginLeft: "10px"}}/>
                  <IconButton onClick={handleClose}>
                      <Close color="primary"/>
                  </IconButton>
              </Stack>
              {
                pages.map((page) => <div onClick={handleClose} key={page.name} >
                    <MenuLink name={page.name} link={page.path} />
                  </div>)
              }

            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={4} sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <Link href="/" height={"100%"}><img src={logo} alt="Glazenwasserij Wens Malle" style={{width: isMobile ? "200px" : "400px"}}/></Link>

            <IconButton size="large" />
            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
              {pages.slice(1).map((page: Page) => (
                <Button
                  key={page.name}
                  onClick={handleClose}
                  sx={styles.headerButton}
                  href={page.path}
                >     <Box mx={4}>
                      {page.name}
                      </Box>
                </Button>
              ))}
            </Box>
          </Stack>
        </Toolbar>
    </AppBar>
  );
};
export default ResponsiveAppBar;
