import { Fade, Typography } from "@mui/material";

interface SubtitleProps {};

const subtitle = `
Glazenwasserij Wens is uw eerste aanspreekpunt als het op schoonmaak aankomt. 
Niet alleen voor de ramen van uw woning of bedrijfspand kan u ons contacteren, maar ook voor uw koepels, veranda's, dakgoten, rolluiken of zonnepanelen. 
Is je gevel, oprit of dakgoot aan reiniging toe? Dan komen wij graag eens langs voor een vrijblijvende offerte. 

Ga je liever zelf aan de slag? 
Dan kan je ook bij ons terecht voor verhuur van onze machines. 

Een specifieke vraag? Aarzel niet om ze te stellen.
`
const styles = {
    text: {
        textAlign: "center",
        fontFamily: "Arial",
        color: "black",
        fontSize: {xs: 16, lg: 24},
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        px: {
            lg: 24,
            xs: 2
        }
    }
}
export default function Subtitle(props: SubtitleProps) {
    return <Fade in={true} timeout={3000}>
        <Typography variant="h2" sx={styles.text} py={{xs: 2, lg: 4}}>{subtitle}</Typography>
        </Fade>
}