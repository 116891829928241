import React, { useState } from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";
import useIsMobile from "../../utils/isMobile";


interface Image {
    img: string
    title: string
}

interface GalleryProps {
    images: Array<Image>
    columns?: number
}


function Gallery(props: GalleryProps) {
    const [showImage, setShowImage] = useState<boolean>(false);
    const [imgIndex, setImgIndex] = useState<number>(0);
    const [imgLoaded, setImgLoaded] = useState<boolean>(false);

    const imgWidth = useIsMobile() ? "100%" : "50%"
    return (
    <>
        <ImageList cols={(props.columns || 3)} >
          {props.images.map((item: Image, index: number) => (
            
                <ImageListItem key={item.img}>
                <img
                    src={`${item.img}`}
                    srcSet={`${item.img}`}
                    style={{
                        width: undefined,
                        aspectRatio: "1"
                    }}
                    alt={item.img}
                    loading="lazy"
                    onClick={() => {
                        if (index !== imgIndex) {
                            setImgLoaded(false);
                        }
                        setImgIndex(index);
                        setShowImage(true);
                    }}
                />
                </ImageListItem>
          ))}
        </ImageList>
        <Backdrop
            sx={{ color: "#fff", zindex: 1 }}
            open={showImage}
            onClick={() => {setShowImage(false)}}
        >
            {
                imgLoaded ? <></> : <CircularProgress color="inherit" />
            }
            <img
                src={`${props.images[imgIndex].img}`}
                alt=""
                style={{
                    width: imgLoaded ? imgWidth : 0,
                    height: undefined,
                    aspectRatio: "1",
                }}
                onLoad={() => {setImgLoaded(true);}}
            />
        </Backdrop>
    </>
      );
};

export default Gallery;