import { Grid } from "@mui/material";
import BlueSideBar from "../../components/BlueSideBar";
import Gallery from "../../components/Gallery";
import i1 from "../../img/stoomreiniging/1.jpeg";
import i2 from "../../img/stoomreiniging/2.jpeg";
import i3 from "../../img/stoomreiniging/3.jpeg";
import i4 from "../../img/stoomreiniging/4.jpeg";
import i5 from "../../img/stoomreiniging/5.jpeg";
import i6 from "../../img/stoomreiniging/6.jpeg";
import i7 from "../../img/stoomreiniging/7.jpeg";
import i8 from "../../img/stoomreiniging/8.jpeg";
import i9 from "../../img/stoomreiniging/9.jpeg";
import i10 from "../../img/stoomreiniging/10.jpeg";
import i11 from "../../img/stoomreiniging/11.jpeg";
import i12 from "../../img/stoomreiniging/12.jpeg";
import i13 from "../../img/stoomreiniging/13.jpeg";
import i14 from "../../img/stoomreiniging/14.jpeg";
import i15 from "../../img/stoomreiniging/15.jpeg";
import { steamPage } from "../../data/pages";
import useIsMobile from "../../utils/isMobile";

const images = [
    {
        img: i1,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i2,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i3,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i4,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i5,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i6,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i7,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i8,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i9,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i10,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i11,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i12,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i13,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i14,
        title: "Stoomreiniging van daken, gevels en opritten"
    },
    {
        img: i15,
        title: "Stoomreiniging van daken, gevels en opritten"
    }
]

const headerText = `Bij stoomreiniging wordt er gebruik gemaakt van water tot 110 graden en lichte druk. 

Dit zorgt ervoor dat het oppervlak zo min mogelijk beschadigd raakt bij reiniging van bijvoorbeeld mossen en schimmels.
`

function Photos() {

    return <Grid item p={{xs: 0, lg: 8}}>
        <Gallery images={images} />
    </Grid>
}

function Body() {
    return <Grid item xs={12} md={8}>
        <Grid container direction="column">
            <Photos />
        </Grid>
    </Grid>
}
function Stoomreiniging() {
    return useIsMobile() ? (
        <Grid container direction="column" pt={10}>
            <BlueSideBar width={12} page={steamPage} headerText={headerText}/>
            <Body />
        </Grid>
    ) : (
        <Grid container direction="row" pt={12}>
            <Body />
            <BlueSideBar width={4} page={steamPage} headerText={headerText}/>
        </Grid>
    )
}

export default Stoomreiniging;