import { Box, Stack, Typography } from "@mui/material";
import { themeColors } from "../../../theme/theme";
import { Menu } from "@mui/icons-material";

interface TitleProps {

}

const styles = {
    text: {
        fontSize: 48,
        fontWeight: 900,
        color: "black",
    },
    e: {

    },
    divider: {
        width: "300px",
        height: "10px",
        backgroundColor: themeColors.primary
    }
}

function E() {
    return <Menu 
        viewBox={'5 3 10 20'} 
        preserveAspectRatio= 'none'
        color="primary"
        style={{
            width: "30px",
            height: '52px'
        }}
    />
}

export default function Title(props: TitleProps) {
    return <Stack direction={"column"}>
            <Stack direction={"row"} spacing={0.8} alignItems={"center"} pt={4}>
                <Typography sx={styles.text}>{"DI"}</Typography>
                <E/>
                <Typography sx={styles.text}>{"NST"}</Typography>
                <E/>
                <Typography sx={styles.text}>{"N"}</Typography>

            </Stack>
            <Box sx={styles.divider}/>
        </Stack>
}